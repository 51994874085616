<template>
  <div class="search">
    <input
      type="text"
      v-model="search"
      :class="{ 'with-region': isRegion }"
      :placeholder="placeholder"
      :style="`background:${bg ? bg : '#f6f6f6'}`"
      @input="(e) => $emit('search-handler', e.target.value)"
    />

    <button
      v-if="isRegion"
      class="region cursor-pointer"
      @click="openDropdown"
    >
      <span>{{ region }}</span>
      <svg width="16" height="16" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="25" height="25" transform="translate(25.5 0.5) rotate(90)" fill="transparent" />
        <path
          d="M19.9571 10.5821C20.3476 10.1916 20.3476 9.55842 19.9571 9.16789C19.5666 8.77737 18.9334 8.77737 18.5429 9.16789L19.9571 10.5821ZM13.7366 15.3884L13.0295 14.6813L13.0295 14.6813L13.7366 15.3884ZM12.2634 15.3884L12.9705 14.6813L12.9705 14.6813L12.2634 15.3884ZM7.45711 9.16789C7.06658 8.77737 6.43342 8.77737 6.04289 9.16789C5.65237 9.55842 5.65237 10.1916 6.04289 10.5821L7.45711 9.16789ZM18.5429 9.16789L13.0295 14.6813L14.4437 16.0955L19.9571 10.5821L18.5429 9.16789ZM12.9705 14.6813L7.45711 9.16789L6.04289 10.5821L11.5563 16.0955L12.9705 14.6813ZM13.0295 14.6813C13.0132 14.6976 12.9868 14.6976 12.9705 14.6813L11.5563 16.0955C12.3536 16.8929 13.6464 16.8929 14.4437 16.0955L13.0295 14.6813Z"
          :fill="scssVars.main" />
      </svg>
    </button>

    <button
      class="search-btn"
      :style="`background:${bg ? bg : '#f6f6f6'}`"
      @click="$emit('search-handler', search)"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.8619 25.8047C25.1222 26.0651 25.5443 26.0651 25.8047 25.8047C26.065 25.5444 26.065 25.1223 25.8047 24.8619L24.8619 25.8047ZM23.0667 15.2C23.0667 19.5447 19.5446 23.0667 15.2 23.0667V24.4001C20.281 24.4001 24.4 20.2811 24.4 15.2H23.0667ZM15.2 23.0667C10.8553 23.0667 7.33329 19.5447 7.33329 15.2H5.99996C5.99996 20.2811 10.119 24.4001 15.2 24.4001V23.0667ZM7.33329 15.2C7.33329 10.8554 10.8553 7.33334 15.2 7.33334V6.00001C10.119 6.00001 5.99996 10.119 5.99996 15.2H7.33329ZM15.2 7.33334C19.5446 7.33334 23.0667 10.8554 23.0667 15.2H24.4C24.4 10.119 20.281 6.00001 15.2 6.00001V7.33334ZM20.8619 21.8047L24.8619 25.8047L25.8047 24.8619L21.8047 20.8619L20.8619 21.8047Z"
          fill="#6610F5"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import VueTypes from "vue-types";
import { scssVars } from "@/utils/constants";
import localize from "@/filters/localize.filter";

export default {
  props: {
    placeholder: VueTypes.string,
    bg: VueTypes.string,
    isRegion: VueTypes.bool.def(false),
    isInput: VueTypes.bool.def(true),
    region: VueTypes.string.def(localize('overUZB'))
  },
  data: () => ({
    search: "",
    scssVars
  }),
  methods: {
    localize,
    openDropdown() {
      this.$emit("open-dropdown");
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;

  .region {
    border: none;
    background-color: $grey;
    width: 30%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $main;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  input {
    width: 100%;
    padding: 15px 12px;
    background: $grey;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $black;
    border: none;
    outline: none;

    &.with-region {
      width: 80%;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: rgba(30, 30, 30, 0.3);
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  button.search-btn {
    padding: 11px 20px;
    line-height: 0;
    background: $grey;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
      border: none;
    }
  }
}
</style>
