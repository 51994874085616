/*
* Search
* @return collection
* [
*   {
*     name: 'John',
*     age: 19,
*   },
*   {
*     name: 'Doe',
*     age: 23,
*   }
* ]
*
* */

export default function (collection = [{}], searchItem = '', value = '') {
  return collection.filter(object => {
    if (object[searchItem]) {
      return value
        .toLowerCase()
        .split(' ')
        .every(searchValue => object[searchItem].toLowerCase().includes(searchValue));
    }
  });
}