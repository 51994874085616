<template>
  <div class="faq">
    <header>
      <h1>{{ localize("faq")["title"] }}</h1>
    </header>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-xl-10 col-ml-10 m-a">
            <Search
              class="mb-16"
              :placeholder="$t('search')"
              bg="#F6F6F6"
              :is-region="true"
              :region="region"
              @search-handler="filter"
              @open-dropdown="openDropdown"
            />

            <div class="faqs-dropdown-content" style="display: none">
              <p
                v-for="faq in dropdownItems"
                :key="faq.id"
                class="cursor-pointer"
                @click="closeDropdown(faq.id, faq.title)"
              >
                {{ faq.title }}
              </p>
            </div>
          </div>
        </div>

        <div :id="faq.id" class="row" v-for="(faq, index) in faqItems" :key="index">
          <div class="col-xl-10 col-ml-10 m-a">
            <h2 :id="faq.id">{{ faq.title }}</h2>

            <ul>
              <li v-for="(faqQuestion, index) in faq.quest" :key="index">
                <div class="faq_head">
                  <div class="icon">
                    <img src="../assets/icons/faqArrow.svg" alt="" />
                  </div>

                  <h3>{{ faqQuestion.head }}</h3>
                </div>

                <div class="faq_body">
                  <p>{{ faqQuestion.title }}</p>

                  <ul v-if="faqQuestion.lists">
                    <li
                      v-for="(subItem, index) in faqQuestion.lists"
                      :key="index"
                      v-html="subItem"
                    ></li>
                  </ul>

                  <p><b>{{ faqQuestion.subtitle }}</b></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import localize from "../filters/localize.filter";
import SearchCategory from "@/components/shop_section/searchCategory";
import search from "@/services/search";

export default {
  data: () => ({
    faqs: localize("faq-quest"),
    filtered: [],
    region: localize("selectCategoryQuestion"),
    timeout: null
  }),
  computed: {
    dropdownItems() {
      return localize("faq-quest").map(faq => ({ id: faq.id, title: faq.title }));
    },
    faqItems() {
      return !this.filtered.length ? this.faqs : this.filtered;
    }
  },
  components: {
    Search: SearchCategory
  },
  methods: {
    localize,
    filter(searchValue) {
      setTimeout(() => {
        const result = search(this.faqItems, "title", searchValue);
        this.filtered = searchValue === "" ? this.faqs : result;
      }, 500);
    },
    openDropdown() {
      $(".faqs-dropdown-content").slideToggle(400);
    },
    closeDropdown(faqId, faqTitle) {
      this.region = faqTitle;

      this.filtered = this.faqs.filter(faq => faq.id === faqId);

      $(".faqs-dropdown-content").slideUp();
    }
  },
  mounted() {
    $(".faq_head").click(function() {
      $(this).next().slideToggle();
      $(".faq_body").not($(this).next()).slideUp();

      $(this).find(".icon img").toggleClass("active");
    });
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/css/faq.scss';

header {
  text-align: center;
  padding: 56px 0;
  background: $grey url('../assets/icons/Vector.svg') no-repeat calc(50% - 120px) 37px;

  h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
  }
}

.faqs-dropdown-content {
  background: $grey;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px 16px;
  width: 50%;
  float: right;
  margin: 8px auto 24px;

  p {
    line-height: 30px;
    transition: color .2s linear;

    &:hover {
      color: $main;
    }
  }
}
</style>
