export const scssVars = {
  main: "#6610F5",
  secondary: "#B182FF",
  black: "#2A2A2A",
  grey: "#F6F6F6",
  muted: "#696969",
  "main-light": "rgba($main, .8)",
  peach: "rgba($main, 0.1)",
  fail: "#F84343",
  "fail-light": "rgba($fail, .15)",
  success: "#53DB8C",
  disabled: " #D8D8D8",
  white: "#fff",
  "box-shadow-color": "rgba(102, 16, 245, 0.25)",
  "border-radius": "14px",
  mobile: "575px",
  tablet: "768px",
  "tablet-xl": "991px",
  notebook: "1366px"
};